<script>
import { to } from "await-to-js";
import { mapState } from "pinia";
import {useEnvStore} from "@dutypay/store-modules";
import clone from "lodash/clone";
export default {
  name: "InfrastructureComponentDetails",
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      updateLoading: false,
      change: false,
      infrastructureComponent: {},
      infrastructureComponentToUpdate: {},
      types: [
        { value: "app", label: this.$t("frontendApplication.label") },
        { value: "api", label: this.$t("api.label") },
        { value: "service", label: this.$t("services.label") },
      ],
      typesLookup: {
        app: this.$t("frontendApplication.label"),
        api: this.$t("api.label"),
        service: this.$t("services.label"),
      },
      showEditForm: false,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls", "debug"]),
  },
  methods: {
    printError(err) {
      if (this.debug) {
        this.$dp.log(err);
      }
    },
    async getInfrastructureComponent(id) {
      this.loading = true;
      let err, res;
      [err, res] = await to(
        this.$http.get(
          `${this.apiUrls.dutypay}/api/v0/infrastructure-components/${id}`
        )
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      this.infrastructureComponent = res.data;
      this.infrastructureComponentToUpdate = clone(
        this.infrastructureComponent
      );
      this.loading = false;
    },
    async updateInfrastructureComponent(){
      this.updateLoading = true;
      let err;
      [err] = await to(
        this.$http.patch(
          `${this.apiUrls.dutypay}/api/v0/infrastructure-components/${this.id}`,
          this.infrastructureComponentToUpdate
        )
      );
      if (err) {
        this.printError(err);
        this.updateLoading = false;
        return;
      }
      this.updateLoading = false;
      await this.getInfrastructureComponent(this.id);
      this.change = false;
      return 1;
    },
    goBackToInfrastructureComponentList() {
      this.$router.push({ name: "InfrastructureComponentList" });
    },
    displayInfrastructureComponent(infrastructureComponent) {
      let copy = clone(infrastructureComponent);
      delete copy.id;
      delete copy.apiKey;
      return copy;
    },
  },
  async mounted() {
    await this.getInfrastructureComponent(this.id);
  },
};
</script>

<template>
  <div>
    <h1>{{ $t("infrastructureComponentDetails.label") }}</h1>
    <div style="padding-top: 1rem; padding-bottom: 1rem">
      <el-button
        type="primary"
        @click="goBackToInfrastructureComponentList()"
        >{{ $t("backToInfrastructureComponentList.buttonLabel") }}</el-button
      >
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <DpSimpleCrud
          v-if="infrastructureComponent"
          :headline="$t('infrastructureComponent.label')"
          :value="{}"
          v-loading="loading"
          v-on:activate-slot="change = true"
          :showDefaultSlot="change"
        >
          <template #read>
            <DpReadValues
              :data="displayInfrastructureComponent(infrastructureComponent)"
              :key-map="{
                id: $t('id.label'),
                name: $t('name.label'),
                hub: $t('hub.label'),
                slug: $t('slug.label'),
                type: $t('type.label'),
                url: $t('url.label'),
                apiKey: $t('apiKey.label'),
                'sp.component.id': $t('spComponentId.label'),
              }"
              :screen-size-is-mobile="screenSizeIsMobile"
              :bdo-key-map="{
                mobile: 'ltr',
              }"
            />
            <h1>{{ $t("apiKey.title") }}</h1>
            <div
              style="padding-top: 1rem; padding-bottom: 1rem"
            >
              <el-input
                v-model="infrastructureComponent['apiKey']"
                :readonly="true"
              />
              <DpCopyToClipboard
                :value="infrastructureComponent['apiKey']"
                :inline="true"
                style="padding-top: 1rem; padding-bottom: 1rem"
              />
            </div>
          </template>
          <DpForm
            v-model="infrastructureComponentToUpdate"
            v-loading="updateLoading"
            :action="updateInfrastructureComponent"
            reference="updateInfrastructureComponentForm"
            :primaryButtonLabel="
              $t('updateInfrastructureComponentForm.primary.buttonLabel')
            "
          >
            <el-row :gutter="20">
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="infrastructureComponentToUpdate.name"
                  :tooltip="
                    $t('infrastructureComponentToUpdate.name.tooltip.label')
                  "
                  :placeholder="
                    $t('infrastructureComponentToUpdate.name.placeholder.label')
                  "
                  :label="$t('infrastructureComponentToUpdate.name.label')"
                  required
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="infrastructureComponentToUpdate.hub"
                  :tooltip="
                    $t('infrastructureComponentToUpdate.hub.tooltip.label')
                  "
                  :placeholder="
                    $t('infrastructureComponentToUpdate.hub.placeholder.label')
                  "
                  :label="$t('infrastructureComponentToUpdate.hub.label')"
                  required
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="infrastructureComponentToUpdate.slug"
                  :tooltip="
                    $t('infrastructureComponentToUpdate.slug.tooltip.label')
                  "
                  :placeholder="
                    $t('infrastructureComponentToUpdate.slug.placeholder.label')
                  "
                  :label="$t('infrastructureComponentToUpdate.slug.label')"
                  required
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpSelectCustom
                  v-model="infrastructureComponentToUpdate.type"
                  :label="$t('infrastructureComponentToUpdate.type.label')"
                  :options="types"
                  prop="type"
                  :t-function="$t"
                  :inputParameters="{}"
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="infrastructureComponentToUpdate.url"
                  :tooltip="
                    $t('infrastructureComponentToUpdate.url.tooltip.label')
                  "
                  :placeholder="
                    $t('infrastructureComponentToUpdate.url.placeholder.label')
                  "
                  :label="$t('infrastructureComponentToUpdate.url.label')"
                  required
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="infrastructureComponentToUpdate['sp.component.id']"
                  :tooltip="
                    $t(
                      'infrastructureComponentToUpdate.sp.component.id.tooltip.label'
                    )
                  "
                  :placeholder="
                    $t(
                      'infrastructureComponentToUpdate.sp.component.id.placeholder.label'
                    )
                  "
                  :label="
                    $t('infrastructureComponentToUpdate.sp.component.id.label')
                  "
                  required
                />
              </el-col>
            </el-row>

            <template #secondaryButton>
              <el-button @click="change = false">{{
                $t("cancel.buttonLabel")
              }}</el-button>
            </template>
          </DpForm>
        </DpSimpleCrud>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss"></style>

<i18n>
{
  "ar-AE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ar-EG": {
    "name": {},
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ar-SA": {
    "name": {},
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "de-DE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "de-AT": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "de-CH": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "en-US": {
    "name": {
      "label": "Designation"
    },
    "hub": {
      "label": "Application"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Application"
      }
    }
  },
  "en-GB": {
    "name": {
      "label": "Designation"
    },
    "hub": {
      "label": "Application"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Application"
      }
    }
  },
  "it-IT": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "fr-FR": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "es-ES": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "pl-PL": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "nl-NL": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "bg-BG": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "bs-BA": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "da-DK": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "et-EE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "fi-FI": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "el-GR": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "he-IL": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ga-IE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ja-JP": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "hr-HR": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "lv-LV": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "lt-LT": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Application"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Application"
      }
    }
  },
  "pt-PT": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ro-RO": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "ru-RU": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "sv-SE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "sk-SK": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "cs-CZ": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "tr-TR": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "hu-HU": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "fr-BE": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "fr-LU": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "mt-MT": {
    "name": {
      "label": ""
    },
    "hub": {
      "label": ""
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": ""
      }
    }
  },
  "sl-SI": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "el-CY": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "tr-CY": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "uk-UA": {
    "name": {},
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  },
  "zh-CN": {
    "name": {
      "label": "Bezeichnung"
    },
    "hub": {
      "label": "Applikation"
    },
    "infrastructureComponentToUpdate": {
      "hub": {
        "label": "Applikation"
      }
    }
  }
}
</i18n>
