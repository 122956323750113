<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n({useScope: 'global'})
</script>
<script>
import { to } from "await-to-js";
import { mapState } from "pinia";
import {useEnvStore} from "@dutypay/store-modules";
export default {
  name: "InfrastructureComponentList",
  data() {
    return {
      loading: true,
      page: 1,
      search: "",
      infrastructureComponents: [],
      showAddForm: false,
      infrastructureComponentToAdd: {},
      types: [
        { value: "app", label: this.$t("frontendApplication.label") },
        { value: "api", label: this.$t("api.label") },
        { value: "service", label: this.$t("services.label") },
      ],
      typesLookup: {
        "app": this.$t("frontendApplication.label"),
        "api": this.$t("api.label"),
        "service": this.$t("services.label"),
      },
      columns: [
        {
          prop: "name",
          label: this.$t("name.label"),
        },
        {
          prop: "hub",
          label: this.$t("hub.label"),
        },
        {
          prop: "slug",
          label: this.$t("slug.label"),
        },
        {
          prop: "type",
          label: this.$t("type.label"),
        },
        {
          prop: "url",
          label: this.$t("url.label"),
        },
        {
          prop: "spComponentId",
          label: this.$t("sp.component.id.label"),
        },
      ],
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls", "debug"]),
    filter() {
      return [];
    },
  },
  methods: {
    printError(err) {
      if (this.debug) {
        this.$dp.log(err);
      }
    },
    async getInfrastructureComponents(page = 1) {
      this.loading = true;
      let err, res;
      [err, res] = await to(
        this.$http.get(
          `${this.apiUrls.dutypay}/api/v0/infrastructure-components`,
          {
            params: {
              page: page,
              filter: this.filter.join(","),
              search: this.search,
            },
          }
        )
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      this.infrastructureComponents = res.data;
      this.loading = false;
    },
    async addInfrastructureComponent() {
      this.loading = true;
      let err;
      [err] = await to(
        this.$http.post(
          `${this.apiUrls.dutypay}/api/v0/infrastructure-components`,
          this.infrastructureComponentToAdd
        )
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      await this.getInfrastructureComponents();
      this.loading = false;
      return 1
    },
    closeAddForm() {
      this.showAddForm = false;
    },
    // goToInfrastructureComponentDetails(id){
    //   this.$router.push({ name: 'InfrastructureComponentDetails', params: { id } })
    // }
  },
  async mounted() {
    await this.getInfrastructureComponents();
  },
};
</script>

<template>
  <div>
    <h1>{{ t("infrastructureComponents.label") }}</h1>
    <div style="padding-top: 1rem; padding-bottom: 1rem">
      <el-button type="primary" @click="showAddForm = !showAddForm">{{
        t("addInfrastructureComponent.buttonLabel")
      }}</el-button>
    </div>
    <DpCollapse v-show="!!showAddForm">
      <div class="partition depth-1">
        <DpForm
          v-model="infrastructureComponentToAdd"
          v-loading="loading"
          :action="addInfrastructureComponent"
          reference="infrastructureComponentToAddForm"
          :primaryButtonLabel="
            t('infrastructureComponentToAddForm.primary.buttonLabel')
          "
        >
          <el-row :gutter="20">
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpInputCustom
                v-model="infrastructureComponentToAdd.name"
                prop="name"
                :tooltip="t('infrastructureComponentToAdd.name.tooltip.label')"
                :placeholder="
                  t('infrastructureComponentToAdd.name.placeholder.label')
                "
                :label="t('infrastructureComponentToAdd.name.label')"
                required
              />
            </el-col>
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpInputCustom
                v-model="infrastructureComponentToAdd.hub"
                prop="hub"
                :tooltip="t('infrastructureComponentToAdd.hub.tooltip.label')"
                :placeholder="
                  t('infrastructureComponentToAdd.hub.placeholder.label')
                "
                :label="t('infrastructureComponentToAdd.hub.label')"
                required
              />
            </el-col>
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpInputCustom
                v-model="infrastructureComponentToAdd.slug"
                prop="slug"
                :tooltip="t('infrastructureComponentToAdd.slug.tooltip.label')"
                :placeholder="
                  t('infrastructureComponentToAdd.slug.placeholder.label')
                "
                :label="t('infrastructureComponentToAdd.slug.label')"
                required
              />
            </el-col>
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpSelectCustom
                v-model="infrastructureComponentToAdd.type"
                prop="type"
                :label="t('infrastructureComponentToAdd.type.label')"
                :options="types"
                :t-function="t"
              />
            </el-col>
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpInputCustom
                v-model="infrastructureComponentToAdd.url"
                prop="url"
                :tooltip="t('infrastructureComponentToAdd.url.tooltip.label')"
                :placeholder="
                  t('infrastructureComponentToAdd.url.placeholder.label')
                "
                :label="t('infrastructureComponentToAdd.url.label')"
                required
              />
            </el-col>
            <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
              <DpInputCustom
                  prop="sp.component.id"
                v-model="infrastructureComponentToAdd['sp.component.id']"
                :tooltip="
                  t(
                    'infrastructureComponentToAdd.sp.component.id.tooltip.label'
                  )
                "
                :placeholder="
                  t(
                    'infrastructureComponentToAdd.sp.component.id.placeholder.label'
                  )
                "
                :label="
                  t('infrastructureComponentToAdd.sp.component.id.label')
                "
                required
              />
            </el-col>
          </el-row>
          <!--<template slot="secondaryButton">
            <el-button @click="closeAddForm">{{
              t("cancel.buttonLabel")
            }}</el-button>
          </template>-->
        </DpForm>
      </div>
    </DpCollapse>
    <el-row :gutter="20">
      <el-col :xl="{ span: 24 }" :sm="{ span: 24 }" :xs="{ span: 24 }">
        <DpTable
          v-loading="loading"
          :data="infrastructureComponents"
          reference="infrastructureComponents"
          :columns="columns"
          size="large"
          class="infrastructure_components__dp-table"
          style="width: 100%"
        >
          <template #name="prop">
            <div style="display: inline-flex">
              <router-link
                :to="{
                  name: 'InfrastructureComponentDetails',
                  params: { id: prop.row.id },
                }"
                >{{ prop.row.name }}</router-link
              >
            </div>
          </template>
          <template #hub="prop">
            <div style="display: inline-flex">
              {{ prop.row.hub }}
            </div>
          </template>
          <template #slug="prop">
            <div style="display: inline-flex">
              {{ prop.row.slug }}
            </div>
          </template>
          <template #type="prop">
            <div style="display: inline-flex">
              {{ typesLookup[prop.row.type] }}
            </div>
          </template>
          <template #url="prop">
            <div style="display: inline-flex">
              {{ prop.row.url }}
            </div>
          </template>
          <template #spComponentId="prop">
            <div style="display: inline-flex">
              {{ prop.row['sp.component.id'] }}
            </div>
          </template>
        </DpTable>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss"></style>

<i18n>
{
  "ar-AE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "يضيف"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ar-EG": {
    "infrastructureComponents": {},
    "name": {},
    "addInfrastructureComponent": {
      "buttonLabel": ""
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ar-SA": {
    "infrastructureComponents": {},
    "name": {},
    "addInfrastructureComponent": {
      "buttonLabel": ""
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "de-DE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Hinzufügen"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "de-AT": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Hinzufügen"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "de-CH": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Hinzufügen"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "en-US": {
    "infrastructureComponents": {
      "label": "Infrastructure components"
    },
    "name": {
      "label": "Designation"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Add"
    },
    "hub": {
      "label": "Application"
    }
  },
  "en-GB": {
    "infrastructureComponents": {
      "label": "Infrastructure components"
    },
    "name": {
      "label": "Designation"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Add"
    },
    "hub": {
      "label": "Application"
    }
  },
  "it-IT": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Inserisci"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "fr-FR": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Ajouter"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "es-ES": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Agregar"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "pl-PL": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Dodaj"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "nl-NL": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Toevoegen"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "bg-BG": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Добавяне"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "bs-BA": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Dodati"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "da-DK": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Tilføje"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "et-EE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Lisama"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "fi-FI": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Lisätä"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "el-GR": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Προσθήκη"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "he-IL": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "לְהוֹסִיף"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ga-IE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Cuir leis"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ja-JP": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "追加"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "hr-HR": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Dodati"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "lv-LV": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Pievienot"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "lt-LT": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Papildyti"
    },
    "hub": {
      "label": "Application"
    }
  },
  "pt-PT": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Adicionar"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ro-RO": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Adăuga"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "ru-RU": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Добавлять"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "sv-SE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Lägg till"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "sk-SK": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Pridať"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "cs-CZ": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Přidat"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "tr-TR": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Ekle"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "hu-HU": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Hozzáadás"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "fr-BE": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Ajouter"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "fr-LU": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Ajouter"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "mt-MT": {
    "infrastructureComponents": {
      "label": ""
    },
    "name": {
      "label": ""
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Żid"
    },
    "hub": {
      "label": ""
    }
  },
  "sl-SI": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Dodaj"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "el-CY": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Προσθήκη"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "tr-CY": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "Ekle"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "uk-UA": {
    "infrastructureComponents": {},
    "name": {},
    "addInfrastructureComponent": {
      "buttonLabel": "Додати до"
    },
    "hub": {
      "label": "Applikation"
    }
  },
  "zh-CN": {
    "infrastructureComponents": {
      "label": "Infrastrukturkomponenten"
    },
    "name": {
      "label": "Bezeichnung"
    },
    "addInfrastructureComponent": {
      "buttonLabel": "添加"
    },
    "hub": {
      "label": "Applikation"
    }
  }
}
</i18n>
