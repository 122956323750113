export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ar-EG": {
        "name": {
          
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ar-SA": {
        "name": {
          
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "de-DE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "de-AT": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "de-CH": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "en-US": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
          }
        }
      },
      "en-GB": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
          }
        }
      },
      "it-IT": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "fr-FR": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "es-ES": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "pl-PL": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "nl-NL": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "bg-BG": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "bs-BA": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "da-DK": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "et-EE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "fi-FI": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "el-GR": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "he-IL": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ga-IE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ja-JP": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "hr-HR": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "lv-LV": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "lt-LT": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
          }
        }
      },
      "pt-PT": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ro-RO": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "ru-RU": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "sv-SE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "sk-SK": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "cs-CZ": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "tr-TR": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "hu-HU": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "fr-BE": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "fr-LU": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "mt-MT": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          }
        }
      },
      "sl-SI": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "el-CY": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "tr-CY": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "uk-UA": {
        "name": {
          
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      },
      "zh-CN": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        },
        "infrastructureComponentToUpdate": {
          "hub": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
          }
        }
      }
    }
  })
}
