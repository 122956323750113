export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يضيف"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ar-EG": {
        "infrastructureComponents": {
          
        },
        "name": {
          
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ar-SA": {
        "infrastructureComponents": {
          
        },
        "name": {
          
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "de-DE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "de-AT": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "de-CH": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "en-US": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructure components"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        }
      },
      "en-GB": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructure components"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        }
      },
      "it-IT": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "fr-FR": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "es-ES": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "pl-PL": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "nl-NL": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "bg-BG": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "bs-BA": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodati"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "da-DK": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføje"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "et-EE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisama"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "fi-FI": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätä"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "el-GR": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "he-IL": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְהוֹסִיף"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ga-IE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir leis"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ja-JP": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "hr-HR": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodati"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "lv-LV": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "lt-LT": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildyti"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])}
        }
      },
      "pt-PT": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ro-RO": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăuga"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "ru-RU": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлять"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "sv-SE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "sk-SK": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "cs-CZ": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "tr-TR": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "hu-HU": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "fr-BE": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "fr-LU": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "mt-MT": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żid"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "sl-SI": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "el-CY": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "tr-CY": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "uk-UA": {
        "infrastructureComponents": {
          
        },
        "name": {
          
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      },
      "zh-CN": {
        "infrastructureComponents": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastrukturkomponenten"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
        },
        "addInfrastructureComponent": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])}
        },
        "hub": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation"])}
        }
      }
    }
  })
}
