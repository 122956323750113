<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Assets",
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
