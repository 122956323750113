<script>
import { to } from "await-to-js";
import { mapState } from "pinia";
import {useEnvStore} from "@dutypay/store-modules";
import clone from "lodash/clone";
import {inject} from 'vue';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default {
  name: "AssetImageDetails",
  setup() {
    const composables = inject('composables');
    const { isMobile } = composables.useViewportSize();
    return { isMobile }
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      updateLoading: false,
      change: false,
      hubs: [],
      asset: {},
      assetToUpdate: {},
      showEditForm: false,
      showDeleteConfirm: false,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
    dayjs(){
      return dayjs;
    },
  },
  methods: {
    printError(err) {
      if (this.debug) {
        this.$dp.log(err);
      }
    },
    async getAsset(id) {
      this.loading = true;
      let err, res;
      [err, res] = await to(
        this.$http.get(`${this.apiUrls.dutypay}/api/v0/assets/${id}`)
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      this.asset = res.data;
      this.assetToUpdate = clone(this.asset);
      this.loading = false;
    },

    async getHubs() {
      this.loading = true;
      let err, res;
      [err, res] = await to(
        this.$http.get(
          `${this.apiUrls.dutypay}/api/v0/infrastructure-components/all/hubs`
        )
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      this.hubs = res.data;
      this.loading = false;
    },
    async updateAsset() {
      this.updateLoading = true;
      let err;
      [err] = await to(
        this.$http.patch(
          `${this.apiUrls.dutypay}/api/v0/assets/${this.id}`,
          this.assetToUpdate
        )
      );
      if (err) {
        this.printError(err);
        this.updateLoading = false;
        return;
      }
      this.updateLoading = false;
      await this.getAsset(this.id);
      this.change = false;
      return 1;
    },
    async deleteAsset() {
      this.updateLoading = true;
      let err;
      [err] = await to(
        this.$http.delete(
          `${this.apiUrls.dutypay}/api/v0/assets/${this.id}`,
          this.assetToUpdate
        )
      );
      if (err) {
        this.printError(err);
        this.updateLoading = false;
        return;
      }
      this.updateLoading = false;
      this.goBackToAssetImageList();
      return 1;
    },
    goBackToAssetImageList() {
      this.$router.push({ name: "AssetImageList" });
    },
    displayAsset(asset) {
      let copy = clone(asset);
      delete copy.uid;
      copy.upload_time = dayjs
        .utc(copy.upload_time)
        .local()
        .format("LLL");
      this.$dp.log(copy.mime_types)
      // copy.mime_types = JSON.parse(copy.mime_types);
      return copy;
    },
  },
  async mounted() {
    await this.getAsset(this.id);
    await this.getHubs();
  },
};
</script>

<template>
  <div>
    <h1>{{ $t("assetDetails.label") }}</h1>

    <h2>
      <el-button class="back-button" type="text" @click="goBackToAssetImageList()">
        <i class="el-icon-arrow-left" :class="{'flip-icon': rtl}"/> {{ $t('backToAssetImageList.buttonLabel') }}
      </el-button>
    </h2>

    <el-row :gutter="20">
      <el-col :span="24">
        <DpSimpleCrud
          v-if="asset"
          :headline="$t('asset.label')"
          :value="{}"
          v-loading="loading"
          v-on:activate-slot="change = true"
          :showDefaultSlot="change"
        >
          <template #read>
            <el-row :gutter="20">
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <el-image
                  :src="`${apiUrls.dutypay}/api/v0/assets/image/${id}/webp`"
                  style="width: 90%; height: auto; padding: 1rem; background-color: #DEDEDE"
                />
              </el-col>
              <el-col :xl="{ span: 16 }" :sm="{ span: 12 }" :xs="{ span: 24 }">

                <DpReadValues
                  :data="displayAsset(asset)"
                  :key-map="{
                    blob: $t('id.label'),
                    original_filename: $t('originalFilename.label'),
                    original_mime_type: $t('originalMimetype.label'),
                    media_type: $t('mediaType.label'),
                    image_type: $t('imageType.label'),
                    image_object: $t('imageObject.label'),
                    image_style: $t('imageStyle.label'),
                    application_or_service: $t('applicationOrService.label'),
                    upload_time: $t('uploadTime.label'),
                    mime_types: $t('mimeTypes.label'),
                    'sp.hubsystem.image.id': $t('spHubsystemImageId.label'),
                  }"
                  :screen-size-is-mobile="isMobile"
                />
              </el-col>
            </el-row>
          </template>
          <DpForm
            v-model="assetToUpdate"
            v-loading="updateLoading"
            :action="updateAsset"
            reference="updateAssetForm"
            :primaryButtonLabel="$t('updateAssetForm.primary.buttonLabel')"
          >
            <el-row :gutter="20">
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <el-image
                    :src="`${apiUrls.dutypay}/api/v0/assets/${id}/webp`"
                    style="width: 90%; height: auto; padding: 1rem; background-color: #DEDEDE"
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="assetToUpdate.image_type"
                  :placeholder="$t('assetToUpdate.imageType.placeholder.label')"
                  :label="$t('assetToUpdate.imageType.label')"
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="assetToUpdate.image_object"
                  :placeholder="
                    $t('assetToUpdate.imageObject.placeholder.label')
                  "
                  :label="$t('assetToUpdate.imageObject.label')"
                />
              </el-col>
              <el-col :xl="{ span: 8 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="assetToUpdate.image_style"
                  :placeholder="
                    $t('assetToUpdate.imageStyle.placeholder.label')
                  "
                  :label="$t('assetToUpdate.imageStyle.label')"
                />
              </el-col>
              <el-col :xl="{ span: 12 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpInputCustom
                  v-model="assetToUpdate['sp.hubsystem.image.id']"
                  :placeholder="
                    $t('assetToUpdate.spHubsystemImageId.placeholder.label')
                  "
                  :label="$t('assetToUpdate.spHubsystemImageId.label')"
                />
              </el-col>
              <el-col :xl="{ span: 12 }" :sm="{ span: 12 }" :xs="{ span: 24 }">
                <DpSelectCustom
                  v-model="assetToUpdate.application_or_service"
                  :label="$t('assetToUpdate.application_or_service.label')"
                  :options="hubs"
                  prop="hub"
                  :t-function="$t"
                  :inputParameters="{}"
                />
              </el-col>
            </el-row>

            <div class="partition depth-1">
              <h3>{{$t('deleteAsset.title')}}</h3>
              <el-button
                  type="danger"
                  v-show="!showDeleteConfirm"
                  @click="showDeleteConfirm = !showDeleteConfirm"
              >{{ $t("deleteAsset.buttonLabel") }}</el-button>
              <el-button-group v-show="!!showDeleteConfirm">
                <el-button @click="showDeleteConfirm = false">{{
                    $t("cancelDeleteAsset.buttonLabel")
                  }}</el-button>
                <el-button
                    type="danger"
                    @click="deleteAsset()"
                >{{ $t("confirmDeleteAsset.buttonLabel") }}</el-button>
              </el-button-group>
            </div>

            <template #secondaryButton>
              <el-button @click="change = false">{{
                $t("cancel.buttonLabel")
              }}</el-button>
            </template>
          </DpForm>
        </DpSimpleCrud>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
