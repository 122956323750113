<script>
export default {
  name: "InfrastructureComponents",
  data() {
    return {}
  }
};
</script>

<template>
  <div>
    <router-view/>
  </div>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
