export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوط"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخالات لكل صفحة"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملف"])}
        }
      },
      "ar-EG": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوط"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخالات لكل صفحة"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملف"])}
        }
      },
      "ar-SA": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوط"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخالات لكل صفحة"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملف"])}
        }
      },
      "de-DE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftarten"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge pro Seite"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])}
        }
      },
      "de-AT": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftarten"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge pro Seite"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])}
        }
      },
      "de-CH": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftarten"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge pro Seite"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])}
        }
      },
      "en-US": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonts"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries per page"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])}
        }
      },
      "en-GB": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonts"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries per page"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])}
        }
      },
      "it-IT": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voci per pagina"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del file"])}
        }
      },
      "fr-FR": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polices"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées par page"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de fichier"])}
        }
      },
      "es-ES": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuentes"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas por página"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del archivo"])}
        }
      },
      "pl-PL": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czcionki"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgrywać"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisy na stronę"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa pliku"])}
        }
      },
      "nl-NL": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettertypen"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaden"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzendingen per pagina"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsnaam"])}
        }
      },
      "bg-BG": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрифтове"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качване"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записи на страница"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на файл"])}
        }
      },
      "bs-BA": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontovi"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unosi po stranici"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime dokumenta"])}
        }
      },
      "da-DK": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrifttyper"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæg pr. side"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filnavn"])}
        }
      },
      "et-EE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondid"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadi üles"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sissekanded lehekülje kohta"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faili nimi"])}
        }
      },
      "fi-FI": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontit"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkinnät per sivu"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedoston nimi"])}
        }
      },
      "el-GR": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμματοσειρές"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφόρτωση"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταχωρήσεις ανά σελίδα"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονομα αρχείου"])}
        }
      },
      "he-IL": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["גופנים"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["העלה"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערכים לכל עמוד"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם קובץ"])}
        }
      },
      "ga-IE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clónna"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uaslódáil"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontrálacha in aghaidh an leathanaigh"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainm comhaid"])}
        }
      },
      "ja-JP": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォント"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページあたりのエントリ"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名"])}
        }
      },
      "hr-HR": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontovi"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitaj"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unosi po stranici"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv datoteke"])}
        }
      },
      "lv-LV": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonti"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādēt"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieraksti katrā lapā"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faila nosaukums"])}
        }
      },
      "lt-LT": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šriftai"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įkelti"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašai viename puslapyje"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failo pavadinimas"])}
        }
      },
      "pt-PT": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontes"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas por página"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do arquivo"])}
        }
      },
      "ro-RO": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonturi"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intrări pe pagină"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume de fișier"])}
        }
      },
      "ru-RU": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрифты"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записей на странице"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла"])}
        }
      },
      "sv-SE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teckensnitt"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inlägg per sida"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filnamn"])}
        }
      },
      "sk-SK": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Písma"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záznamy na stránku"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov súboru"])}
        }
      },
      "cs-CZ": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Písma"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záznamy na stránku"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název souboru"])}
        }
      },
      "tr-TR": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı tipleri"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklemek"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa başına giriş sayısı"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya adı"])}
        }
      },
      "hu-HU": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betűtípusok"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltöltés"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejegyzések oldalanként"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájl név"])}
        }
      },
      "fr-BE": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polices"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées par page"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de fichier"])}
        }
      },
      "fr-LU": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polices"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées par page"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de fichier"])}
        }
      },
      "mt-MT": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonts"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrati għal kull paġna"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isem tal-fajl"])}
        }
      },
      "sl-SI": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisave"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naloži"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnosi na stran"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime datoteke"])}
        }
      },
      "el-CY": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμματοσειρές"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφόρτωση"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταχωρήσεις ανά σελίδα"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονομα αρχείου"])}
        }
      },
      "tr-CY": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı tipleri"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklemek"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa başına giriş sayısı"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya adı"])}
        }
      },
      "uk-UA": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрифти"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записів на сторінці"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я файлу"])}
        }
      },
      "zh-CN": {
        "assetFonts": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字体"])}
        },
        "uploadAsset": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])}
        },
        "pageSize": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每页条目数"])}
        },
        "fileName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名"])}
        }
      }
    }
  })
}
